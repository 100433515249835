import { useEffect, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css'

const ComingsoonComponent = lazy(() => import('./pages/Comingsoon/Comingsoon'));
const SalesPageComponent = lazy(() => import('./pages/SalesPage/SalesPage'));
const ExpiredPageComponent = lazy(() => import('./pages/Expired/Expired'));
const NotFound = lazy(() => import('./pages/404/index'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage/Thank'));

const TRACKING_ID = "UA-75736354-20"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    TagManager.initialize({ gtmId: 'GTM-5DKZZ2P' });
    AOS.init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={< SalesPageComponent/>} />
          <Route path="/ComingSoon" element={<ComingsoonComponent />} />
          <Route path="/expired-offer" element={<ExpiredPageComponent />} />
          <Route path="*" element={<NotFound/>}/>
          <Route path="/tq-stp" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
