import React from "react";

export default function Footer() {
  return (
    <>
      <p className="text-center text-drak bg-white py-4 mb-0">
        @ {new Date().getFullYear()} Built with Jose Learning Center
      </p>
    </>
  );
}
